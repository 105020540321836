import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import vintafloLogo from '../../assets/vintaflow-logo.png';
import FileUpload from './FileUpload';

const Sidebar = ({ activeView, setActiveView }) => {
  const navigate = useNavigate();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const menuItems = [
    
    {
      id: 'forecast',
      label: 'Retailer Forecast',
      icon: '📊'
    },
    {
      id: 'overview',
      label: 'Supply Chain Overview',
      icon: '🔄'
    },
    {
      id: 'joint-replenishment',
      label: 'Joint Replenishment',
      icon: '🚛'
    }
  ];

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(true);
    // Refresh the page after a short delay to show success state
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="w-64 bg-white shadow-sm flex flex-col h-full">
      {/* Logo Section */}
      <div className="p-4 border-b border-gray-200 bg-gradient-to-b from-blue-50 to-white">
        <img 
          src={vintafloLogo} 
          alt="Vintaflo Logo" 
          className="h-12 w-auto mx-auto"
        />
        <div className="text-center mt-2 text-sm font-medium text-gray-600">
          Supply Chain Management
        </div>
      </div>

      {/* Navigation Menu */}
      <div className="flex-1 px-3 py-4">
        <div className="space-y-1">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => setActiveView(item.id)}
              className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors duration-150 ${
                activeView === item.id
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <span className="mr-3">{item.icon}</span>
              {item.label}
            </button>
          ))}
        </div>
      </div>

      {/* File Upload Section */}
      <div className="p-4 border-t border-gray-200">
        <FileUpload onUploadSuccess={handleUploadSuccess} />
      </div>

      {/* Logout Section */}
      <div className="p-4 border-t border-gray-200">
        <button
          onClick={handleLogout}
          className="w-full flex items-center px-3 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
        >
          <span className="mr-3">🚪</span>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar; 