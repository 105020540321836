import { Box } from '@mui/material';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      width: '100%'
    }}>
      <Box sx={{ 
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        width: '100%'
      }}>
        <Navbar />
      </Box>

      <Box sx={{ 
        flexGrow: 1,
        width: '100%',
        overflow: 'auto'
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout; 