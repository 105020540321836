import React, { useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const SupplyChainParameters = ({ 
  title,
  parameters,
  onParameterChange,
  showTopArrow = true,
  showBottomArrow = true 
}) => {
  // Local state to track input values before committing
  const [localValues, setLocalValues] = useState(parameters);

  // Handle input changes without triggering parameter updates
  const handleInputChange = (field, value) => {
    setLocalValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Commit changes on blur or Enter key
  const handleCommitChange = (field, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      onParameterChange(field, numValue);
    }
  };

  // Handle key press for Enter
  const handleKeyPress = (e, field) => {
    if (e.key === 'Enter') {
      handleCommitChange(field, e.target.value);
    }
  };

  return (
    <div className="relative py-8">
      {showTopArrow && (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-500">
          <div className="text-xs text-gray-600">Order Flow</div>
          <FaArrowDown size={24} />
        </div>
      )}
      
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
        <h4 className="text-lg font-medium text-gray-700 mb-4">{title} Parameters</h4>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Lead Time (weeks)
            </label>
            <input
              type="number"
              min="0"
              value={localValues.leadTime}
              onChange={(e) => handleInputChange('leadTime', Number(e.target.value))}
              onBlur={(e) => handleCommitChange('leadTime', e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, 'leadTime')}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Container/Pallet Size
            </label>
            <input
              type="number"
              min="1"
              value={localValues.containerSize}
              onChange={(e) => handleInputChange('containerSize', Number(e.target.value))}
              onBlur={(e) => handleCommitChange('containerSize', e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, 'containerSize')}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Minimum Order Quantity
            </label>
            <input
              type="number"
              min="0"
              value={localValues.minOrderQty}
              onChange={(e) => handleInputChange('minOrderQty', Number(e.target.value))}
              onBlur={(e) => handleCommitChange('minOrderQty', e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, 'minOrderQty')}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Safety Stock Factor
            </label>
            <input
              type="number"
              min="0"
              step="0.1"
              value={localValues.safetyFactor}
              onChange={(e) => handleInputChange('safetyFactor', Number(e.target.value))}
              onBlur={(e) => handleCommitChange('safetyFactor', e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, 'safetyFactor')}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
        </div>
      </div>

      {showBottomArrow && (
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-500">
          <FaArrowUp size={24} />
          <div className="text-xs text-gray-600">Product Flow</div>
        </div>
      )}
    </div>
  );
};

export default SupplyChainParameters; 