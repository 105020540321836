import React, { useState } from 'react';
import Sidebar from '../common/Sidebar';
import RetailerForecast from './RetailerForecast';
import OrderRecommendations from './OrderRecommendations';
import SupplyChainOverview from './SupplyChainOverview';
import JointReplenishment from './JointReplenishment';

const Dashboard = () => {
  const [activeView, setActiveView] = useState('forecast');

  return (
    <div className="h-screen flex">
      <Sidebar 
        activeView={activeView} 
        setActiveView={setActiveView} 
      />
      <main className="flex-1 overflow-auto bg-gray-50 p-6">
        {activeView === 'forecast' ? (
          <RetailerForecast />
        ) : activeView === 'overview' ? (
          <SupplyChainOverview />
        ) : activeView === 'joint-replenishment' ? (
          <JointReplenishment />
        ) : null}
      </main>
    </div>
  );
};

export default Dashboard; 