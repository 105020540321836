import React, { useState, useRef } from 'react';
import { uploadApi } from '../../services/api';

const FileUpload = ({ onUploadSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleUpload = async (file) => {
    if (!file) return;

    if (file.type !== 'text/csv') {
      setError('Please select a CSV file');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await uploadApi.uploadFile(formData);
      if (response.data.success) {
        onUploadSuccess(response.data);
      } else {
        setError(response.data.error || 'Upload failed');
      }
    } catch (err) {
      setError('Upload failed: ' + err.message);
    } finally {
      setLoading(false);
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        className="hidden"
        onChange={(e) => handleUpload(e.target.files[0])}
      />
      <button
        onClick={() => fileInputRef.current?.click()}
        disabled={loading}
        className={`w-full flex justify-center items-center px-4 py-2 text-sm font-medium rounded-md
          ${loading 
            ? 'bg-gray-400 cursor-not-allowed' 
            : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
      >
        {loading ? (
          <span>Updating Data...</span>
        ) : (
          <span>Update Data</span>
        )}
      </button>
      {error && (
        <div className="mt-2 text-xs text-red-600">
          {error}
        </div>
      )}
    </div>
  );
};

export default FileUpload; 