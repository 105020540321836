import React, { useState, useEffect } from 'react';
import { ordersApi } from '../../services/api';

const OrderRecommendations = () => {
  const [selectedDistributor, setSelectedDistributor] = useState('');
  const [recommendations, setRecommendations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [distributors, setDistributors] = useState([]);

  // Fetch available distributors when component mounts
  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        const response = await ordersApi.getDistributors();
        setDistributors(response.data.distributors);
      } catch (error) {
        console.error('Error fetching distributors:', error);
        setError('Failed to load distributors');
      }
    };

    fetchDistributors();
  }, []);

  const handleGetRecommendations = async () => {
    if (!selectedDistributor) {
      setError('Please select a distributor');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await ordersApi.getRecommendations({
        distributor: selectedDistributor
      });
      setRecommendations(response.data.recommendations);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Order Recommendations</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Select Distributor
          </label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            value={selectedDistributor}
            onChange={(e) => setSelectedDistributor(e.target.value)}
          >
            <option value="">Select...</option>
            {distributors.map(distributor => (
              <option key={distributor} value={distributor}>
                {distributor}
              </option>
            ))}
          </select>
        </div>
        {error && (
          <div className="text-sm text-red-600 mb-4">
            {error}
          </div>
        )}
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 disabled:bg-gray-400"
          onClick={handleGetRecommendations}
          disabled={loading || !selectedDistributor}
        >
          {loading ? 'Loading...' : 'Get Recommendations'}
        </button>
      </div>

      {recommendations && (
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">Recommended Orders</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Item
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Current Inventory
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Recommended Order
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Weeks of Supply
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(recommendations).map(([item, data]) => (
                  <tr key={item}>
                    <td className="px-6 py-4 whitespace-nowrap">{item}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{data.currentInventory}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{data.recommendedOrder}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{data.weeksOfSupply}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderRecommendations; 