import React from 'react';

const EchelonFilter = ({ 
  companies, 
  selectedCompany, 
  onCompanyChange,
  echelon,
  className = "" 
}) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <label className="text-sm text-gray-600">Filter by Company:</label>
      <select
        value={selectedCompany}
        onChange={(e) => onCompanyChange(e.target.value)}
        className="border border-gray-300 rounded-md shadow-sm p-1 text-sm"
      >
        <option value="">All Companies</option>
        {companies.map(company => (
          <option key={company} value={company}>{company}</option>
        ))}
      </select>
    </div>
  );
};

export default EchelonFilter; 