import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import SupplyChainParameters from './SupplyChainParameters.jsx';
import EchelonFilter from './EchelonFilter.jsx';
import { supplyChainApi, forecastApi } from '../../services/api';

// Define the safety stock zone plugin
const safetyStockZonePlugin = {
  id: 'safetyStockZone',
  beforeDraw(chart) {
    const { ctx, chartArea, scales } = chart;
    if (!scales.inventory || !chartArea) return;

    const data = chart.data.raw;
    if (!data) return;

    // Find the historical/forecast split point
    const historicalData = data.filter(d => !d.isForecast);
    const forecastData = data.filter(d => d.isForecast);
    if (!forecastData.length) return;

    // Calculate average safety stock and reorder point for forecast period
    const avgSafetyStock = forecastData.reduce((sum, d) => sum + d.safetyStock, 0) / forecastData.length;
    const avgReorderPoint = forecastData.reduce((sum, d) => sum + d.reorderPoint, 0) / forecastData.length;

    // Get the start of forecast period
    const startX = scales.x.getPixelForValue(historicalData.length);
    const endX = chartArea.right;

    // Convert levels to y-coordinates
    const safetyStockY = scales.inventory.getPixelForValue(avgSafetyStock);
    const reorderPointY = scales.inventory.getPixelForValue(avgReorderPoint);

    // Draw the shaded area
    ctx.save();
    ctx.fillStyle = 'rgba(255, 200, 200, 0.3)';

    // Draw the rectangle between the lines
    ctx.fillRect(
      startX,
      reorderPointY,
      endX - startX,
      safetyStockY - reorderPointY
    );

    // Draw the lines
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(255, 150, 150, 0.5)';
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);

    // Draw reorder point line
    ctx.moveTo(startX, reorderPointY);
    ctx.lineTo(endX, reorderPointY);

    // Draw safety stock line
    ctx.moveTo(startX, safetyStockY);
    ctx.lineTo(endX, safetyStockY);

    ctx.stroke();
    ctx.restore();
  }
};

// Register all components and the plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  safetyStockZonePlugin
);

const processForecastData = (forecastResponse, parameters) => {
  const originalData = forecastResponse.originalData;
  const chartData = forecastResponse.chartData;
  const historicalLength = chartData.metadata?.historicalDataLength || 0;
  
  // Get the total dataset from chartData
  const totalDataset = chartData.datasets.find(d => d.label === 'Total') || chartData.datasets[0];
  
  // Get unique companies for each echelon from historical data
  const companies = {
    retailers: new Set(),
    distributors: new Set(),
    importers: new Set(),
    producers: new Set()
  };

  originalData?.forEach(record => {
    if (!record.isForecast) {
      if (record.retailer) companies.retailers.add(record.retailer);
      if (record.distributor) companies.distributors.add(record.distributor);
      if (record.importer) companies.importers.add(record.importer);
      if (record.producer) companies.producers.add(record.producer);
    }
  });

  // Helper function to calculate inventory levels
  const calculateInventoryLevels = (historicalDemand, leadTime, safetyFactor) => {
    if (!historicalDemand.length) return { reorderPoint: 0, safetyStock: 0 };
    
    const avgDemand = historicalDemand.reduce((sum, d) => sum + d, 0) / historicalDemand.length;
    const stdDev = Math.sqrt(
      historicalDemand.reduce((sum, d) => sum + Math.pow(d - avgDemand, 2), 0) / 
      historicalDemand.length
    ) || avgDemand * 0.3;

    const safetyStock = safetyFactor * Math.sqrt(leadTime) * stdDev;
    const leadTimeDemand = avgDemand * leadTime;
    const reorderPoint = leadTimeDemand + safetyStock;

    return { reorderPoint, safetyStock };
  };

  // Create temporary array to store processed data
  const tempProcessedData = [];

  // Process data week by week
  chartData.labels.forEach((week, index) => {
    const isHistorical = index < historicalLength;
    
    // Find all records for this week
    const weekRecords = originalData?.filter(d => d.master_week === week) || [];
    const retailerRecords = weekRecords.filter(r => r.echelon === 'Retailer');

    // Calculate historical totals exactly as they appear in the CSV
    const historicalTotals = retailerRecords.reduce((totals, record) => ({
      inbound: totals.inbound + Number(record.inbound_quantity || 0),
      outbound: totals.outbound + Number(record.outbound_quantity || 0),
      ending_inventory: Math.max(0, Number(record.end_inventory || 0))
    }), { inbound: 0, outbound: 0, ending_inventory: 0 });

    // Get the forecasted outbound quantity from the chartData
    const outboundQty = isHistorical ? 
      historicalTotals.outbound : 
      totalDataset.data[index];

    // Calculate inbound quantity
    let inboundQty;
    if (isHistorical) {
      inboundQty = historicalTotals.inbound;
    } else {
      // For forecast periods, calculate inbound based on safety stock and reorder point
      const prevPeriod = tempProcessedData[index - 1];
      const currentInventory = prevPeriod ? 
        prevPeriod.ending_inventory : 
        historicalTotals.ending_inventory;
      
      // Get historical demand for calculations
      const historicalDemand = tempProcessedData
        .slice(Math.max(0, index - 12), index)
        .map(d => Number(d.outbound_quantity))
        .filter(d => d > 0);

      // Calculate inventory levels using retailerDistributor parameters
      const levels = calculateInventoryLevels(
        historicalDemand,
        parameters.retailerDistributor.leadTime,
        parameters.retailerDistributor.safetyFactor
      );

      // Calculate target inventory level for retailer
      const targetInventory = levels.reorderPoint + outboundQty;
      
      // Calculate required inbound quantity (from distributor)
      const requiredQty = Math.max(0, targetInventory - currentInventory);

      // Round to retailerDistributor container size
      if (parameters.retailerDistributor.containerSize) {
        inboundQty = Math.max(
          Math.ceil(requiredQty / parameters.retailerDistributor.containerSize) 
            * parameters.retailerDistributor.containerSize,
          parameters.retailerDistributor.minOrderQty
        );
      }
    }

    // Calculate ending inventory
    const endingInventory = isHistorical ?
      historicalTotals.ending_inventory :
      Math.max(0, (tempProcessedData[index - 1]?.ending_inventory || 0) + inboundQty - outboundQty);

    // For forecast periods, calculate safety stock and reorder point
    let safetyStock = 0;
    let reorderPoint = 0;
    
    if (!isHistorical) {
      // Get historical demand for calculations
      const historicalDemand = tempProcessedData
        .slice(Math.max(0, index - 12), index)
        .map(d => Number(d.outbound_quantity))
        .filter(d => d > 0);

      // Calculate inventory levels using retailer parameters
      const levels = calculateInventoryLevels(
        historicalDemand,
        parameters.retailerDistributor.leadTime,
        parameters.retailerDistributor.safetyFactor
      );
      
      safetyStock = levels.safetyStock;
      reorderPoint = levels.reorderPoint;
    }

    // Create period data with company information
    const periodData = {
      master_week: week,
      outbound_quantity: outboundQty,
      inbound_quantity: inboundQty,
      ending_inventory: endingInventory,
      isForecast: !isHistorical,
      safetyStock,
      reorderPoint,
      // Store company information for all echelons
      retailer: {
        company: retailerRecords[0]?.retailer || '',
        inbound_quantity: inboundQty,
        outbound_quantity: outboundQty,
        ending_inventory: endingInventory,
        allCompanies: Array.from(companies.retailers).sort()
      },
      distributor: {
        company: retailerRecords[0]?.distributor || '',
        allCompanies: Array.from(companies.distributors).sort()
      },
      importer: {
        company: retailerRecords[0]?.importer || '',
        allCompanies: Array.from(companies.importers).sort()
      },
      producer: {
        company: retailerRecords[0]?.producer || '',
        allCompanies: Array.from(companies.producers).sort()
      }
    };

    tempProcessedData.push(periodData);
  });

  return tempProcessedData;
};

const InventoryChart = ({ data, type }) => {
  const chartRef = useRef(null);
  const historicalData = data.filter(d => !d.isForecast);

  const chartData = {
    labels: data.map(d => d.master_week),
    raw: data,
    datasets: [
      // Inventory Level (except for producer)
      ...(type !== 'producer' ? [{
        type: 'line',
        label: 'Inventory Level',
        data: data.map(d => Number(d.ending_inventory) || 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderWidth: 2,
        tension: 0.4,
        yAxisID: 'inventory',
        pointRadius: 0,
        segment: {
          borderDash: context => context.p1DataIndex >= historicalData.length ? [5, 5] : undefined
        },
        order: 1
      }] : []),

      // Inbound Orders
      ...(type !== 'producer' ? [{
        type: 'bar',
        label: 'Inbound Orders',
        data: data.map(d => Number(d.inbound_quantity) || 0),
        backgroundColor: (context) => {
          const index = context.dataIndex;
          return index < historicalData.length ? 
            'rgba(54, 162, 235, 0.9)' : // Darker blue for historical
            'rgba(54, 162, 235, 0.6)';  // Lighter blue for forecast
        },
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
        yAxisID: 'orders',
        order: 2,
        barPercentage: 0.8, // Make bars wider
        categoryPercentage: 0.9
      }] : []),
      
      // Outbound Orders
      {
        type: 'bar',
        label: type === 'producer' ? 'Production Output' : 'Outbound Orders',
        data: data.map(d => Number(d.outbound_quantity) || 0),
        backgroundColor: (context) => {
          const index = context.dataIndex;
          return index < historicalData.length ? 
            'rgba(255, 145, 85, 0.9)' :  // Softer orange for historical
            'rgba(255, 145, 85, 0.6)';   // Lighter soft orange for forecast
        },
        borderColor: 'rgb(255, 145, 85)', // Matching softer orange
        borderWidth: 1,
        yAxisID: 'orders',
        order: 2,
        barPercentage: 0.8,
        categoryPercentage: 0.9
      },

      // Add weeks of inventory line (except for producer)
      ...(type !== 'producer' ? [{
        type: 'line',
        label: 'Weeks of Inventory',
        data: (() => {
          // Calculate historical average WOI once
          const historicalData = data.filter(d => !d.isForecast);
          const historicalInventorySum = historicalData.reduce((sum, d) => 
            sum + Number(d.ending_inventory || 0), 0);
          const historicalDemandSum = historicalData.reduce((sum, d) => 
            sum + Number(d.outbound_quantity || 0), 0);
          const historicalWOI = historicalDemandSum > 0 ? 
            historicalInventorySum / historicalDemandSum : 0;

          // Calculate forecast average WOI once
          const forecastData = data.filter(d => d.isForecast);
          const forecastInventorySum = forecastData.reduce((sum, d) => 
            sum + Number(d.ending_inventory || 0), 0);
          const forecastDemandSum = forecastData.reduce((sum, d) => 
            sum + Number(d.outbound_quantity || 0), 0);
          const forecastWOI = forecastDemandSum > 0 ? 
            forecastInventorySum / forecastDemandSum : 0;

          // Return array with appropriate values for each data point
          return data.map(d => d.isForecast ? forecastWOI : historicalWOI);
        })(),
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        borderWidth: 2,
        tension: 0,
        yAxisID: 'woi',
        pointRadius: 0,
        segment: {
          borderDash: context => context.p1DataIndex >= historicalData.length ? [5, 5] : undefined
        }
      }] : [])
    ].filter(Boolean)
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    parsing: {
      yAxisKey: 'y',
      xAxisKey: 'x'
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      orders: {
        type: 'linear',
        position: 'left',
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        },
        title: {
          display: true,
          text: 'Order Quantity'
        },
        beginAtZero: true
      },
      ...(type !== 'producer' && {
        inventory: {
          type: 'linear',
          position: 'right',
          grid: {
            drawOnChartArea: true,
            color: 'rgba(0, 0, 0, 0.05)'
          },
          title: {
            display: true,
            text: 'Inventory Level'
          },
          beginAtZero: true,
          ticks: {
            padding: 10
          }
        },
        ...(type !== 'producer' && {
          woi: {
            type: 'linear',
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            title: {
              display: true,
              text: 'Weeks of Inventory'
            },
            beginAtZero: true
          }
        })
      })
    },
    plugins: {
      legend: {
        labels: {
          filter: function(legendItem, data) {
            // Don't show Buffer Zone and Safety Stock in legend
            return !['Buffer Zone', 'Safety Stock'].includes(legendItem.text);
          }
        }
      }
    }
  };

  return (
    <div className="w-full h-[400px]">
      <Chart 
        ref={chartRef}
        type="bar" 
        data={chartData} 
        options={options} 
      />
    </div>
  );
};

const calculateSupplyChainOrders = (retailerData, parameters, selectedProduct, originalData) => {
  // Helper function to calculate joint replenishment order quantity
  const calculateJointOrderQuantity = (requiredQty, containerSize, minOrderQty) => {
    // Round up to nearest container size
    const containerCount = Math.ceil(requiredQty / containerSize);
    return Math.max(containerCount * containerSize, minOrderQty);
  };

  // Helper function to calculate inventory levels
  const calculateInventoryLevels = (historicalDemand, leadTime, safetyFactor) => {
    if (!historicalDemand.length) return { reorderPoint: 0, safetyStock: 0 };
    
    const avgDemand = historicalDemand.reduce((sum, d) => sum + d, 0) / historicalDemand.length;
    const stdDev = Math.sqrt(
      historicalDemand.reduce((sum, d) => sum + Math.pow(d - avgDemand, 2), 0) / 
      historicalDemand.length
    ) || avgDemand * 0.3;

    const safetyStock = safetyFactor * Math.sqrt(leadTime) * stdDev;
    const leadTimeDemand = avgDemand * leadTime;
    const reorderPoint = leadTimeDemand + safetyStock;

    return { reorderPoint, safetyStock };
  };

  // Initialize arrays for each node with historical data
  const distributor = [...retailerData].map(d => {
    // Find all distributor records for this week
    const distributorRecords = d.isForecast ? [] : 
      originalData?.filter(r => 
        r.master_week === d.master_week && 
        r.echelon === 'Distributor'
      ) || [];

    // Sum up all distributor quantities for this week
    const weekTotals = distributorRecords.reduce((totals, record) => ({
      inbound: totals.inbound + Number(record.inbound_quantity || 0),
      outbound: totals.outbound + Number(record.outbound_quantity || 0),
      ending_inventory: totals.ending_inventory + Math.max(0, Number(record.end_inventory || 0))
    }), { inbound: 0, outbound: 0, ending_inventory: 0 });

    return {
      master_week: d.master_week,
      inbound_quantity: d.isForecast ? 0 : weekTotals.inbound,
      outbound_quantity: d.isForecast ? 0 : weekTotals.outbound,
      ending_inventory: d.isForecast ? 
        parameters.distributorImporter.containerSize * 2 :  // Changed: Use distributorImporter for distributor's inbound
        weekTotals.ending_inventory,
      start_inventory: Number(d.distributor?.start_inventory) || 0,
      lost_sales: 0,
      safetyStock: 0,
      reorderPoint: 0,
      isForecast: d.isForecast,
      distributor: d.distributor
    };
  });

  // Similarly for importer
  const importer = [...retailerData].map(d => {
    // Find all importer records for this week
    const importerRecords = d.isForecast ? [] :
      originalData?.filter(r => 
        r.master_week === d.master_week && 
        r.echelon === 'Importer' &&
        r.item === selectedProduct  // Add product filter
      ) || [];

    // Sum up all importer quantities for this week
    const weekTotals = importerRecords.reduce((totals, record) => ({
      inbound: totals.inbound + Number(record.inbound_quantity || 0),
      outbound: totals.outbound + Number(record.outbound_quantity || 0),
      ending_inventory: totals.ending_inventory + Math.max(0, Number(record.end_inventory || 0))
    }), { inbound: 0, outbound: 0, ending_inventory: 0 });

    return {
      master_week: d.master_week,
      inbound_quantity: d.isForecast ? 0 : weekTotals.inbound,
      outbound_quantity: d.isForecast ? 0 : weekTotals.outbound,
      ending_inventory: d.isForecast ? 
        parameters.importerProducer.containerSize * 3 :  // Correct: Using importerProducer for importer's inbound
        weekTotals.ending_inventory,
      start_inventory: Number(d.importer?.start_inventory) || 0,
      lost_sales: 0,
      safetyStock: 0,
      reorderPoint: 0,
      isForecast: d.isForecast,
      importer: d.importer
    };
  });

  // Initialize producer data using importer's historical inbound and future orders
  const producer = retailerData.map((d, index) => {
    if (!d.isForecast) {
      // For historical periods, use exact importer inbound from CSV
      const importerRecord = originalData?.find(r =>
        r.master_week === d.master_week &&
        r.echelon === 'Importer' &&
        r.item === selectedProduct
      );
      
      return {
        master_week: d.master_week,
        outbound_quantity: Number(importerRecord?.inbound_quantity || 0),
        isForecast: false
      };
    } else {
      // For forecast periods, use importer's recommended orders
      return {
        master_week: d.master_week,
        outbound_quantity: importer[index]?.inbound_quantity || 0,
        isForecast: true
      };
    }
  });

  // Process distributor orders and inventory for forecast periods
  distributor.forEach((period, index) => {
    if (!period.isForecast) return;

    const outboundParams = parameters.retailerDistributor;    // For outbound to retailer
    const inboundParams = parameters.distributorImporter;     // For inbound from importer
    
    // Set outbound orders to match retailer's inbound orders
    period.outbound_quantity = retailerData[index].inbound_quantity;

    // Calculate current inventory before ordering
    const prevPeriod = index > 0 ? distributor[index - 1] : null;
    const currentInventory = prevPeriod ? prevPeriod.ending_inventory : period.ending_inventory;

    // Calculate average weekly demand based on outbound (to retailer)
    const recentDemand = distributor
      .slice(Math.max(0, index - 12), index)
      .map(d => Number(d.outbound_quantity))
      .filter(d => d > 0);
    
    const avgWeeklyDemand = recentDemand.length > 0 ?
      recentDemand.reduce((sum, d) => sum + d, 0) / recentDemand.length :
      period.outbound_quantity;

    // Calculate safety stock based on inbound parameters (from importer)
    const safetyStock = avgWeeklyDemand * inboundParams.safetyFactor;
    period.safetyStock = safetyStock;

    // Calculate reorder point using inbound parameters (from importer)
    const reorderPoint = (avgWeeklyDemand * inboundParams.leadTime) + safetyStock;
    period.reorderPoint = reorderPoint;

    // Calculate projected demand during inbound lead time
    const leadTimeDemand = retailerData
      .slice(index, Math.min(index + inboundParams.leadTime, retailerData.length))
      .reduce((sum, d) => sum + Number(d.inbound_quantity || 0), 0);

    // Get in-transit orders using inbound parameters
    const inTransitOrders = distributor
      .slice(Math.max(0, index - inboundParams.leadTime), index)
      .reduce((sum, d) => sum + Number(d.inbound_quantity), 0);

    // Calculate inventory position
    const inventoryPosition = currentInventory + inTransitOrders;

    // Calculate target inventory level
    const targetInventory = Math.max(
      reorderPoint,
      leadTimeDemand + safetyStock
    );

    // Order if inventory position is below target using inbound parameters
    if (inventoryPosition < targetInventory) {
      const baseOrderQty = targetInventory - inventoryPosition;
      
      // Use inbound parameters for container size and MOQ
      const containerSize = inboundParams.containerSize;
      const minOrderQty = inboundParams.minOrderQty;
      
      const containers = Math.max(
        Math.ceil(baseOrderQty / containerSize),
        Math.ceil(minOrderQty / containerSize)
      );
      
      period.inbound_quantity = containers * containerSize;
    } else {
      period.inbound_quantity = 0;
    }

    // Calculate ending inventory
    period.ending_inventory = currentInventory + 
      period.inbound_quantity - 
      period.outbound_quantity;

    if (period.ending_inventory < 0) {
      period.lost_sales = -period.ending_inventory;
      period.ending_inventory = 0;
    }
  });

  // Process importer orders and inventory
  importer.forEach((period, index) => {
    if (!period.isForecast) return;

    const outboundParams = parameters.distributorImporter;  // For outbound to distributor
    const inboundParams = parameters.importerProducer;     // For inbound from producer
    
    // Set outbound orders to match distributor's inbound orders
    period.outbound_quantity = distributor[index].inbound_quantity;

    // Calculate current inventory before ordering
    const prevPeriod = index > 0 ? importer[index - 1] : null;
    const currentInventory = prevPeriod ? prevPeriod.ending_inventory : period.ending_inventory;

    // Calculate average weekly demand based on outbound (to distributor)
    const recentDemand = importer
      .slice(Math.max(0, index - 12), index)
      .map(d => Number(d.outbound_quantity))
      .filter(d => d > 0);
    
    const avgWeeklyDemand = recentDemand.length > 0 ?
      recentDemand.reduce((sum, d) => sum + d, 0) / recentDemand.length :
      period.outbound_quantity;

    // Calculate safety stock based on inbound parameters (from producer)
    const safetyStock = avgWeeklyDemand * inboundParams.safetyFactor;
    period.safetyStock = safetyStock;

    // Calculate reorder point using inbound parameters (from producer)
    const reorderPoint = (avgWeeklyDemand * inboundParams.leadTime) + safetyStock;
    period.reorderPoint = reorderPoint;

    // Calculate projected demand during inbound lead time
    const leadTimeDemand = distributor
      .slice(index, Math.min(index + inboundParams.leadTime, distributor.length))
      .map(d => Number(d.inbound_quantity || 0))
      .reduce((sum, d) => sum + d, 0);

    // Get in-transit orders using inbound parameters
    const inTransitOrders = importer
      .slice(Math.max(0, index - inboundParams.leadTime), index)
      .reduce((sum, d) => sum + Number(d.inbound_quantity), 0);

    // Calculate inventory position
    const inventoryPosition = currentInventory + inTransitOrders;

    // Calculate target inventory level
    const targetInventory = Math.max(
      reorderPoint,
      leadTimeDemand + safetyStock
    );

    // Order if inventory position is below target using inbound parameters
    if (inventoryPosition < targetInventory) {
      const baseOrderQty = targetInventory - inventoryPosition;
      
      // Use inbound parameters for container size and MOQ
      const containerSize = inboundParams.containerSize;
      const minOrderQty = inboundParams.minOrderQty;
      
      const containers = Math.max(
        Math.ceil(baseOrderQty / containerSize),
        Math.ceil(minOrderQty / containerSize)
      );
      
      period.inbound_quantity = containers * containerSize;
    } else {
      period.inbound_quantity = 0;
    }

    // Calculate ending inventory
    period.ending_inventory = currentInventory + 
      period.inbound_quantity - 
      period.outbound_quantity;

    if (period.ending_inventory < 0) {
      period.lost_sales = -period.ending_inventory;
      period.ending_inventory = 0;
    }
  });

  // Process producer orders
  producer.forEach((period, index) => {
    if (!period.isForecast) return;

    const params = parameters.importerProducer;  // Use importerProducer parameters
    
    // Set producer's outbound to match importer's inbound with lead time offset
    if (index + params.leadTime < importer.length) {
      period.outbound_quantity = importer[index + params.leadTime].inbound_quantity;
    } else {
      // For periods beyond importer's forecast, maintain last known order quantity
      const lastKnownOrder = importer[importer.length - 1].inbound_quantity;
      period.outbound_quantity = lastKnownOrder;
    }
  });

  // Return all supply chain data
  return {
    distributor,
    importer,
    producer
  };
};

const MetricPills = ({ data, type }) => {
  const historicalData = data.filter(d => !d.isForecast);
  const forecastData = data.filter(d => d.isForecast);

  // Calculate metrics
  const calculateWOI = (records) => {
    const inventorySum = records.reduce((sum, d) => sum + Number(d.ending_inventory || 0), 0);
    const demandSum = records.reduce((sum, d) => sum + Number(d.outbound_quantity || 0), 0);
    return demandSum > 0 ? inventorySum / demandSum : 0;
  };

  const historicalMetrics = {
    avgDemand: historicalData.reduce((sum, d) => sum + Number(d.outbound_quantity || 0), 0) / 
               Math.max(historicalData.length, 1),
    avgWOI: calculateWOI(historicalData),
    avgInventory: historicalData.reduce((sum, d) => sum + Number(d.ending_inventory || 0), 0) / 
                 Math.max(historicalData.length, 1)
  };

  const forecastMetrics = {
    avgDemand: forecastData.reduce((sum, d) => sum + Number(d.outbound_quantity || 0), 0) / 
               Math.max(forecastData.length, 1),
    avgWOI: calculateWOI(forecastData),
    avgInventory: forecastData.reduce((sum, d) => sum + Number(d.ending_inventory || 0), 0) / 
                 Math.max(forecastData.length, 1),
    avgReorderPoint: forecastData.reduce((sum, d) => sum + (d.reorderPoint || 0), 0) / 
                    Math.max(forecastData.length, 1),
    avgSafetyStock: forecastData.reduce((sum, d) => sum + (d.safetyStock || 0), 0) / 
                    Math.max(forecastData.length, 1)
  };

  const growthRate = historicalMetrics.avgDemand > 0 ? 
    ((forecastMetrics.avgDemand - historicalMetrics.avgDemand) / historicalMetrics.avgDemand) * 100 : 0;

  return (
    <div className="flex gap-4 mb-4 flex-wrap">
      <div className="flex gap-2">
        <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
          Historical WOI: {historicalMetrics.avgWOI.toFixed(1)}
        </span>
        <span className="px-2 py-1 bg-indigo-100 text-indigo-800 rounded-full text-sm">
          Forecast WOI: {forecastMetrics.avgWOI.toFixed(1)}
        </span>
      </div>
      <div className="flex gap-2">
        <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-sm">
          Historical Demand: {historicalMetrics.avgDemand.toFixed(1)}
        </span>
        <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">
          Forecast Demand: {forecastMetrics.avgDemand.toFixed(1)}
        </span>
      </div>
      {/* Only show reorder point and safety stock for non-producer types */}
      {type !== 'producer' && (
        <div className="flex gap-2">
          <span className="px-2 py-1 bg-amber-100 text-amber-800 rounded-full text-sm">
            Reorder Point: {forecastMetrics.avgReorderPoint.toFixed(1)}
          </span>
          <span className="px-2 py-1 bg-rose-100 text-rose-800 rounded-full text-sm">
            Safety Stock: {forecastMetrics.avgSafetyStock.toFixed(1)}
          </span>
        </div>
      )}
      <span className={`px-2 py-1 rounded-full text-sm ${
        growthRate >= 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
      }`}>
        Growth Rate: {growthRate.toFixed(1)}%
      </span>
    </div>
  );
};

const SupplyChainOverview = () => {
  // Selection states
  const [selectedProduct, setSelectedProduct] = useState('');
  const [products, setProducts] = useState([]);
  
  // Data states
  const [retailerData, setRetailerData] = useState([]);
  const [distributorData, setDistributorData] = useState([]);
  const [importerData, setImporterData] = useState([]);
  const [producerData, setProducerData] = useState([]);
  
  // Add forecast method state
  const [forecastMethod] = useState('holtWinters'); // Always use Holt-Winters
  
  // Parameters state
  const [parameters, setParameters] = useState({
    retailerDistributor: {
      leadTime: 1,
      containerSize: 1,
      minOrderQty: 1,
      safetyFactor: 1.5
    },
    distributorImporter: {
      leadTime: 2,
      containerSize: 100,
      minOrderQty: 100,
      safetyFactor: 2
    },
    importerProducer: {
      leadTime: 4,
      containerSize: 1740,
      minOrderQty: 1740,
      safetyFactor: 2.5
    }
  });

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Add state for company filters
  const [selectedRetailer, setSelectedRetailer] = useState('');
  const [selectedDistributor, setSelectedDistributor] = useState('');
  const [selectedImporter, setSelectedImporter] = useState('');
  const [selectedProducer, setSelectedProducer] = useState('');
  
  // Add state for available companies
  const [companies, setCompanies] = useState({
    retailers: [],
    distributors: [],
    importers: [],
    producers: []
  });

  // Add new state for cached data
  const [rawForecastData, setRawForecastData] = useState(null);

  // Handle parameter changes
  const handleParameterChange = (section, param, value) => {
    // Update parameters
    setParameters(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [param]: value
      }
    }));

    // If we have raw forecast data, recalculate supply chain without fetching
    if (rawForecastData) {
      const retailerData = processForecastData(rawForecastData, {
        ...parameters,
        [section]: {
          ...parameters[section],
          [param]: value
        }
      });

      setRetailerData(retailerData);

      const supplyChainData = calculateSupplyChainOrders(
        retailerData,
        {
          ...parameters,
          [section]: {
            ...parameters[section],
            [param]: value
          }
        },
        selectedProduct,
        rawForecastData.originalData
      );

      setDistributorData(supplyChainData.distributor);
      setImporterData(supplyChainData.importer);
      setProducerData(supplyChainData.producer);
    }
  };

  // Fetch initial product list
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await supplyChainApi.getProducts();
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products');
      }
    };
    fetchProducts();
  }, []);

  // Modify the fetch data effect to store raw forecast data
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedProduct) return;
      
      try {
        setLoading(true);

        // Get forecast for selected retailer or all retailers
        const forecastResponse = await forecastApi.getForecast({
          retailer: selectedRetailer || 'All',
          item: selectedProduct,
          forecastMethod: 'holtWinters',
          forecastParams: {
            alpha: 0.2,
            beta: 0.1,
            gamma: 0.3,
            period: 12
          }
        });

        // Filter original data based on selected companies
        const filteredOriginalData = forecastResponse.data.originalData.filter(record => {
          if (selectedRetailer && record.retailer !== selectedRetailer) return false;
          if (selectedDistributor && record.distributor !== selectedDistributor) return false;
          if (selectedImporter && record.importer !== selectedImporter) return false;
          if (selectedProducer && record.producer !== selectedProducer) return false;
          return true;
        });

        // Update the forecast response with filtered data
        const filteredResponse = {
          ...forecastResponse.data,
          originalData: filteredOriginalData
        };

        // Store raw forecast data
        setRawForecastData(filteredResponse);

        // Process forecast data with parameters
        const retailerData = processForecastData(filteredResponse, parameters);
        setRetailerData(retailerData);

        // Calculate orders and propagate through supply chain
        const supplyChainData = calculateSupplyChainOrders(
          retailerData, 
          parameters, 
          selectedProduct,
          filteredOriginalData
        );
        
        setDistributorData(supplyChainData.distributor);
        setImporterData(supplyChainData.importer);
        setProducerData(supplyChainData.producer);

        setError(null);
      } catch (err) {
        setError('Failed to load data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProduct, selectedRetailer, selectedDistributor, selectedImporter, selectedProducer]);

  // Extract unique companies for each echelon
  useEffect(() => {
    if (retailerData.length > 0) {
      const firstRecord = retailerData[0];
      const companies = {
        retailers: firstRecord.retailer?.allCompanies || [],
        distributors: firstRecord.distributor?.allCompanies || [],
        importers: firstRecord.importer?.allCompanies || [],
        producers: firstRecord.producer?.allCompanies || []
      };

      console.log('Final Extracted Companies:', companies);
      setCompanies(companies);
    }
  }, [retailerData]);

  // Update filter handlers to maintain relationships
  const handleRetailerChange = (company) => {
    setSelectedRetailer(company);
    // Clear other selections if they don't have a relationship with the selected retailer
    if (company && companies.retailers && companies.retailers[company]) {
      const validDistributors = new Set(companies.retailers[company]?.distributors || []);
      if (!validDistributors.has(selectedDistributor)) {
        setSelectedDistributor('');
      }
    } else {
      setSelectedDistributor('');
    }
  };

  const handleDistributorChange = (company) => {
    setSelectedDistributor(company);
    if (company) {
      const validRetailers = new Set(companies.distributors[company]?.retailers || []);
      const validImporters = new Set(companies.distributors[company]?.importers || []);
      
      if (!validRetailers.has(selectedRetailer)) {
        setSelectedRetailer('');
      }
      if (!validImporters.has(selectedImporter)) {
        setSelectedImporter('');
      }
    }
  };

  const handleImporterChange = (company) => {
    setSelectedImporter(company);
    if (company) {
      const validDistributors = new Set(companies.importers[company]?.distributors || []);
      const validProducers = new Set(companies.importers[company]?.producers || []);
      
      if (!validDistributors.has(selectedDistributor)) {
        setSelectedDistributor('');
      }
      if (!validProducers.has(selectedProducer)) {
        setSelectedProducer('');
      }
    }
  };

  const handleProducerChange = (company) => {
    setSelectedProducer(company);
    if (company) {
      const validImporters = new Set(companies.producers[company]?.importers || []);
      if (!validImporters.has(selectedImporter)) {
        setSelectedImporter('');
      }
    }
  };

  // Get available companies based on current selections
  const getAvailableCompanies = (echelon) => {
    switch (echelon) {
      case 'retailer':
        return Array.isArray(companies.retailers) ? companies.retailers : [];
      case 'distributor':
        return Array.isArray(companies.distributors) ? companies.distributors : [];
      case 'importer':
        return Array.isArray(companies.importers) ? companies.importers : [];
      case 'producer':
        return Array.isArray(companies.producers) ? companies.producers : [];
      default:
        return [];
    }
  };

  return (
    <div className="space-y-8">
      {/* Product Selection */}
      <div className="bg-white p-6 rounded-lg shadow">
        <select
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="w-full border border-gray-300 rounded-md shadow-sm p-2"
        >
          <option value="">Select Product</option>
          {products.map(product => (
            <option key={product} value={product}>{product}</option>
          ))}
        </select>
      </div>

      {loading ? (
        <div className="text-center py-12">Loading...</div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      ) : selectedProduct && (
        <>
          {/* Retailer Section */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Retailer Demand</h3>
              <EchelonFilter
                companies={getAvailableCompanies('retailer')}
                selectedCompany={selectedRetailer}
                onCompanyChange={handleRetailerChange}
                echelon="retailer"
              />
            </div>
            <MetricPills data={retailerData} type="retailer" />
            <InventoryChart data={retailerData} type="retailer" />
          </div>

          {/* Retailer-Distributor Parameters */}
          <SupplyChainParameters
            title="Retailer-Distributor"
            parameters={parameters.retailerDistributor}
            onParameterChange={(param, value) => handleParameterChange('retailerDistributor', param, value)}
          />

          {/* Distributor Section */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Distributor Operations</h3>
              <EchelonFilter
                companies={getAvailableCompanies('distributor')}
                selectedCompany={selectedDistributor}
                onCompanyChange={handleDistributorChange}
                echelon="distributor"
              />
            </div>
            <MetricPills data={distributorData} type="distributor" />
            <InventoryChart data={distributorData} type="distributor" />
          </div>

          {/* Distributor-Importer Parameters */}
          <SupplyChainParameters
            title="Distributor-Importer"
            parameters={parameters.distributorImporter}
            onParameterChange={(param, value) => handleParameterChange('distributorImporter', param, value)}
          />

          {/* Importer Section */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Importer Operations</h3>
              <EchelonFilter
                companies={getAvailableCompanies('importer')}
                selectedCompany={selectedImporter}
                onCompanyChange={handleImporterChange}
                echelon="importer"
              />
            </div>
            <MetricPills data={importerData} type="importer" />
            <InventoryChart data={importerData} type="importer" />
          </div>

          {/* Importer-Producer Parameters */}
          <SupplyChainParameters
            title="Importer-Producer"
            parameters={parameters.importerProducer}
            onParameterChange={(param, value) => handleParameterChange('importerProducer', param, value)}
          />

          {/* Producer Section */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Producer Operations</h3>
              <EchelonFilter
                companies={getAvailableCompanies('producer')}
                selectedCompany={selectedProducer}
                onCompanyChange={handleProducerChange}
                echelon="producer"
              />
            </div>
            <InventoryChart data={producerData} type="producer" />
          </div>
        </>
      )}
    </div>
  );
};

export default SupplyChainOverview; 