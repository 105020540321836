import React from 'react';
import { Link } from 'react-router-dom';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import logo from '../assets/vintaflow-logo.png';

const Navbar = () => {
  return (
    <AppBar position="static" sx={{ 
      backgroundColor: '#f5f5f5',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <Toolbar sx={{ 
        minHeight: '64px',
        backgroundColor: 'rgba(245, 245, 245, 0.95)'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mr: 2
        }}>
          <img 
            src={logo} 
            alt="Vintaflow Logo" 
            style={{ 
              height: '45px',
              marginRight: '20px',
              marginLeft: '10px'
            }} 
          />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2
        }}>
          <Link 
            to="/"
            style={{ 
              textDecoration: 'none', 
              color: '#333333',
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            <InventoryIcon sx={{ 
              color: '#333333',
              opacity: 0.95
            }} />
            <Typography 
              variant="h6"
              sx={{ 
                fontWeight: 400,
                letterSpacing: '0.5px',
                color: '#333333',
                fontSize: '1.1rem'
              }}
            >
              Multi-Echelon Simulation
            </Typography>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar; 