import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, Card, CardContent, FormControl, InputLabel, Select, MenuItem, Tabs, Tab, Table, TableBody, TableCell, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

// Parse DD-MM-YY to Date object
const parseDate = (dateStr) => {
  if (!dateStr) return null;
  
  // Input format: 'DD-MM-YY'
  const [day, month, year] = dateStr.split('-').map(num => parseInt(num, 10));
  const fullYear = 2000 + year; // Convert YY to 20YY
  
  // Create date (months are 0-based in JavaScript)
  return new Date(fullYear, month - 1, day, 12, 0, 0, 0);
};

// Format date for axis labels
const formatAxisDate = (date) => {
  if (!date) return '';
  
  // If date is a string, parse it
  let dateObj;
  try {
    dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
      return '';
    }
    
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    
    return `${month}/${day}/${year}`;
  } catch (e) {
    return '';
  }
};

// Format date for tooltips
const formatTooltipDate = formatAxisDate;

// Format week label with year when it changes
const formatWeekLabel = (date) => {
  try {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
    
    // Get previous week's date
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 7);
    
    // Show year when it changes
    if (prevDate.getFullYear() !== year) {
      return `${month}/${day}\n${year}`;
    }
    
    return `${month}/${day}`;
  } catch (e) {
    return '';
  }
};

// Update the tooltipDateFormatter function to handle both Date objects and strings
const tooltipDateFormatter = (value) => {
  try {
    let date;
    if (value instanceof Date) {
      date = value;
    } else {
      date = new Date(value);
    }
    
    if (isNaN(date.getTime())) {
      return '';
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  } catch (e) {
    return '';
  }
};

// Form control styling to fix label overlap
const formControlStyle = {
  mb: 3,
  '& .MuiInputLabel-root': {
    backgroundColor: 'background.paper',
    px: 0.5,
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiSelect-select': {
    mt: 1
  }
};

// Custom Axis Components with default parameters
const CustomXAxis = ({ 
  dataKey = 'master_week',
  ...props 
}) => (
  <XAxis
    dataKey={dataKey}
    type="category"
    tickFormatter={(value) => {
      if (!value) return '';
      try {
        const date = value instanceof Date ? value : new Date(value);
        return formatWeekLabel(date);
      } catch (e) {
        return '';
      }
    }}
    height={60}
    tickSize={8}
    interval={0}
    angle={-45}
    textAnchor="end"
    dy={20}
    label={{ 
      value: 'Week', 
      position: 'bottom',
      offset: 40
    }}
    {...props}
  />
);

const CustomYAxis = ({ 
  tickFormatter = (value) => value.toLocaleString(),
  orientation = 'left',
  label,
  ...props 
}) => (
  <YAxis
    tickFormatter={tickFormatter}
    orientation={orientation}
    label={label && { 
      value: label, 
      angle: orientation === 'left' ? -90 : 90,
      position: 'insideLeft',
      offset: -5
    }}
    tickCount={5}
    {...props}
  />
);

// Add this helper function at the top level
const parseCustomDate = (dateStr) => {
  if (!dateStr) return null;
  
  try {
    // Input format: 'DD-MM-YY'
    const [day, month, year] = dateStr.split('-').map(num => parseInt(num, 10));
    if (!day || !month || !year) {
      return null;
    }
    
    // Convert 2-digit year to full year (20xx)
    const fullYear = 2000 + year;
    
    // Create date (months are 0-based in JavaScript)
    const date = new Date(fullYear, month - 1, day);
    
    if (isNaN(date.getTime())) {
      return null;
    }
    
    // Set time to noon to avoid timezone issues
    date.setHours(12, 0, 0, 0);
    
    return date;
  } catch (e) {
    return null;
  }
};

const MultiEchelonSimulation = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  
  // Selection states
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedImporters, setSelectedImporters] = useState([]);
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedDetailItem, setSelectedDetailItem] = useState('');
  const [selectedRetailer, setSelectedRetailer] = useState('All');
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedEchelonEntity, setSelectedEchelonEntity] = useState('All');
  
  // Data states
  const [filteredResults, setFilteredResults] = useState(null);
  const [retailerDemand, setRetailerDemand] = useState(null);
  const [retailerData, setRetailerData] = useState(null);

  const getUniqueValues = (data, field) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    
    const allValues = data.map(row => row[field]);
    return [...new Set(allValues.filter(value => value != null && value !== ''))];
  };

  const calculateMetrics = (data) => {
    if (!data || data.length === 0) return null;

    const totalDays = data.length;
    const stockoutDays = data.filter(day => parseFloat(day.end_inventory) <= 0).length;
    const serviceLevel = ((totalDays - stockoutDays) / totalDays) * 100;
    const averageInventory = data.reduce((sum, day) => sum + (parseFloat(day.end_inventory) || 0), 0) / totalDays;
    const totalDemand = data.reduce((sum, day) => sum + (parseFloat(day.outbound_quantity) || 0), 0);
    const inventoryTurnover = totalDemand / (averageInventory || 1); // Avoid division by zero

    return {
      serviceLevel: serviceLevel.toFixed(2),
      inventoryTurnover: inventoryTurnover.toFixed(2),
      averageInventory: Math.round(averageInventory),
      stockoutDays
    };
  };

  const metrics = useMemo(() => {
    return filteredResults ? calculateMetrics(filteredResults) : null;
  }, [filteredResults]);

  // Define a color palette for retailers
  const retailerColors = [
    '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#e91e63',
    '#673ab7', '#3f51b5', '#2196f3', '#009688', '#4caf50'
  ];

  const processRetailerDemand = (data) => {
    if (!data) return { data: [], retailers: [] };

    const retailerData = data.filter(row => 
      row.echelon === 'Retailer' && 
      row.item === selectedDetailItem
    );

    // Group by week
    const groupedData = retailerData.reduce((acc, row) => {
      // Ensure master_week is a Date object
      const weekDate = row.master_week instanceof Date ? row.master_week : new Date(row.master_week);
      const weekStr = weekDate.toISOString();
      
      if (!acc[weekStr]) {
        acc[weekStr] = {
          master_week: weekDate, // Store as Date object
          data_type: row.data_type,
          total: 0
        };
      }
      
      const quantity = parseFloat(row.outbound_quantity) || 0;
      acc[weekStr][row.company] = (acc[weekStr][row.company] || 0) + quantity;
      acc[weekStr].total += quantity;
      
      return acc;
    }, {});

    const sortedData = Object.values(groupedData)
      .sort((a, b) => a.master_week - b.master_week);

    const retailers = [...new Set(retailerData.map(row => row.company))];

    return {
      data: sortedData,
      retailers: retailers
    };
  };

  const renderRetailerDemandChart = (data, retailers) => {
    if (!data?.length) return null;

    return (
      <Box sx={{ height: 400, mb: 3 }}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <CustomXAxis dataKey="master_week" />
            <CustomYAxis />
            <Tooltip
              labelFormatter={(_, payload) => {
                if (!payload || !payload[0]) return '';
                const dataPoint = payload[0].payload;
                return formatAxisDate(dataPoint.master_week);
              }}
              formatter={(value, name) => [parseFloat(value).toLocaleString(), name]}
            />
            <Legend />
            
            {selectedRetailer === 'All' ? (
              <>
                {retailers.map((retailer, index) => (
                  <Line
                    key={retailer}
                    type="monotone"
                    dataKey={retailer}
                    stroke={retailerColors[index % retailerColors.length]}
                    name={retailer}
                    strokeWidth={2}
                    dot={false}
                    connectNulls
                  />
                ))}
                <Line
                  type="monotone"
                  dataKey="total"
                  stroke={theme.palette.grey[900]}
                  name="Total"
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                />
              </>
            ) : (
              <Line
                type="monotone"
                dataKey={selectedRetailer}
                stroke={retailerColors[0]}
                name={selectedRetailer}
                strokeWidth={2}
                dot={false}
                connectNulls
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const calculateDemandStatistics = (data, selectedRetailer) => {
    if (!data?.length) return null;

    // Group data by week to avoid duplicates
    const groupByWeek = (data) => {
      return data.reduce((acc, d) => {
        const week = d.master_week;
        if (!acc[week]) {
          acc[week] = {
            total: 0,
            [selectedRetailer]: 0
          };
        }
        const value = selectedRetailer === 'All' ? 
          (parseFloat(d.total) || 0) : 
          (parseFloat(d[selectedRetailer]) || 0);
        
        acc[week].total += value;
        if (selectedRetailer !== 'All') {
          acc[week][selectedRetailer] += value;
        }
        return acc;
      }, {});
    };

    const historicalData = groupByWeek(data.filter(d => d.data_type === 'Historical'));
    const forecastedData = groupByWeek(data.filter(d => d.data_type === 'Forecasted'));

    const calculateTotal = (weeklyData) => {
      return Object.values(weeklyData).reduce((sum, week) => 
        sum + (selectedRetailer === 'All' ? week.total : week[selectedRetailer]), 0
      );
    };

    const calculateAverage = (weeklyData) => {
      const weeks = Object.values(weeklyData);
      return weeks.length ? 
        calculateTotal(weeklyData) / weeks.length : 
        0;
    };

    const historicalTotal = calculateTotal(historicalData);
    const forecastedTotal = calculateTotal(forecastedData);
    const historicalAvg = calculateAverage(historicalData);
    const forecastedAvg = calculateAverage(forecastedData);

    return {
      "Total Demand": {
        "Past": Math.round(historicalTotal),
        "Forecasted": Math.round(forecastedTotal)
      },
      "Average Weekly Demand": {
        "Past": Math.round(historicalAvg),
        "Forecasted": Math.round(forecastedAvg)
      },
      "Growth Rate": {
        "Past": "-",
        "Forecasted": historicalTotal ? 
          `${Math.round((forecastedTotal/historicalTotal - 1) * 100)}%` : 
          'N/A'
      }
    };
  };

  const renderDemandStatistics = (metrics) => {
    if (!metrics) return null;

    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Demand Statistics Metrics
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                <TableCell align="right">Past</TableCell>
                <TableCell align="right">Forecasted</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(metrics).map(([metric, values]) => (
                <TableRow key={metric}>
                  <TableCell>{metric}</TableCell>
                  <TableCell align="right">{values.Past.toLocaleString()}</TableCell>
                  <TableCell align="right">{values.Forecasted.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

  const renderEchelonDetails = (echelonType) => {
    const filteredData = results?.filter(row => 
      row.echelon === echelonType && 
      row.item === selectedDetailItem
    );

    if (!filteredData?.length) return null;

    // Group data by week for the selected entity
    const groupedData = filteredData.reduce((acc, row) => {
      if (selectedEchelonEntity !== 'All' && row.company !== selectedEchelonEntity) {
        return acc;
      }

      const weekKey = row.master_week.toISOString().split('T')[0];
      if (!acc[weekKey]) {
        acc[weekKey] = {
          master_week: row.master_week,
          data_type: row.data_type,
          inbound_quantity: 0,
          outbound_quantity: 0,
          end_inventory: 0,
          weeks_of_inventory: 0
        };
      }
      
      acc[weekKey].inbound_quantity += parseFloat(row.inbound_quantity) || 0;
      acc[weekKey].outbound_quantity += parseFloat(row.outbound_quantity) || 0;
      acc[weekKey].end_inventory += parseFloat(row.end_inventory) || 0;
      acc[weekKey].weeks_of_inventory = parseFloat(row.weeks_of_inventory) || 0;
      
      return acc;
    }, {});

    const chartData = Object.values(groupedData)
      .map(d => ({
        ...d,
        master_week: d.master_week instanceof Date ? d.master_week : new Date(d.master_week)
      }))
      .sort((a, b) => a.master_week - b.master_week);

    const echelonMetrics = calculateEchelonMetrics(filteredData);

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {echelonType} Details
        </Typography>
        
        <FormControl fullWidth sx={formControlStyle}>
          <InputLabel>Select {echelonType}</InputLabel>
          <Select
            value={selectedEchelonEntity}
            onChange={(e) => setSelectedEchelonEntity(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {getUniqueValues(filteredData, 'company').map(entity => (
              <MenuItem key={entity} value={entity}>{entity}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box sx={{ height: 400, mb: 3 }}>
              <ResponsiveContainer>
                <ComposedChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <CustomXAxis dataKey="master_week" />
                  <CustomYAxis 
                    yAxisId="left" 
                    label="Quantity/Inventory"
                  />
                  <CustomYAxis 
                    yAxisId="right" 
                    orientation="right"
                    domain={[0, 25]}
                    label="Weeks of Inventory"
                  />
                  <Tooltip
                    labelFormatter={(_, payload) => {
                      if (!payload || !payload[0]) return '';
                      const dataPoint = payload[0].payload;
                      return formatAxisDate(dataPoint.master_week);
                    }}
                    formatter={(value, name) => [parseFloat(value).toLocaleString(), name]}
                  />
                  <Legend />
                  
                  <Bar
                    dataKey="inbound_quantity"
                    fill={theme.palette.primary.main}
                    name="Inbound"
                    yAxisId="left"
                    opacity={0.8}
                    barSize={20}
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar
                    dataKey="outbound_quantity"
                    fill={theme.palette.secondary.main}
                    name="Outbound"
                    yAxisId="left"
                    opacity={0.8}
                    barSize={20}
                    radius={[4, 4, 0, 0]}
                  />
                  <Line
                    type="monotone"
                    dataKey="end_inventory"
                    stroke={theme.palette.success.main}
                    name="Inventory"
                    yAxisId="left"
                    strokeWidth={3}
                    dot={false}
                    connectNulls
                  />
                  <Line
                    type="monotone"
                    dataKey="weeks_of_inventory"
                    stroke={theme.palette.error.main}
                    name="Weeks of Inventory"
                    yAxisId="right"
                    strokeWidth={3}
                    dot={false}
                    connectNulls
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {renderEchelonMetrics(echelonMetrics)}
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          {echelonType} Detailed Data Table
        </Typography>
        {renderDetailedDataTable(processDetailedData(filteredData))}
      </Box>
    );
  };

  const processDetailedData = (data) => {
    if (!data?.length) return null;

    const columns = [
      'master_week', 'start_inventory', 'inbound_quantity',
      'outbound_quantity', 'end_inventory', 'orders_placed',
      'on_order', 'weeks_of_inventory'
    ];

    // Group by week first
    const weeklyData = data.reduce((acc, row) => {
      const weekKey = row.master_week instanceof Date 
        ? row.master_week.toISOString() 
        : new Date(row.master_week).toISOString();

      if (!acc[weekKey]) {
        acc[weekKey] = {};
        columns.forEach(col => {
          if (col === 'master_week') {
            acc[weekKey][col] = row.master_week;
          } else {
            acc[weekKey][col] = 0;
          }
        });
        acc[weekKey].data_type = row.data_type;
      }
      
      // Sum up numeric values
      columns.slice(1).forEach(col => {
        acc[weekKey][col] += parseFloat(row[col]) || 0;
      });
      
      return acc;
    }, {});

    // Create transposed data structure
    const transposed = {};
    columns.slice(1).forEach(metric => {
      transposed[metric] = {};
      Object.entries(weeklyData).forEach(([week, values]) => {
        transposed[metric][week] = values[metric];
      });
    });

    return {
      weeks: Object.keys(weeklyData).sort(),
      metrics: transposed
    };
  };

  const calculateEchelonMetrics = (data) => {
    if (!data?.length) return null;

    const filteredData = selectedEchelonEntity === 'All' 
      ? data 
      : data.filter(row => row.company === selectedEchelonEntity);

    const historicalData = filteredData.filter(row => (row.data_type || '').toLowerCase() === 'historical');
    const forecastedData = filteredData.filter(row => (row.data_type || '').toLowerCase() === 'forecasted');

    const processedData = filteredData.map(row => ({
      ...row,
      data_type: row.data_type || determineDataType(row.master_week)
    }));

    const groupByWeek = (data, field) => {
      return data.reduce((acc, row) => {
        const weekKey = row.master_week instanceof Date 
          ? row.master_week.toISOString() 
          : new Date(row.master_week).toISOString();
        
        if (!acc[weekKey]) {
          acc[weekKey] = 0;
        }
        const value = parseFloat(row[field]);
        if (!isNaN(value)) {
          acc[weekKey] += value;
        }
        return acc;
      }, {});
    };

    const calculateAverage = (data, field) => {
      if (!data.length) return 0;
      const weeklyValues = groupByWeek(data, field);
      const values = Object.values(weeklyValues);
      const average = values.length ? values.reduce((sum, val) => sum + val, 0) / values.length : 0;
      return average;
    };

    const calculateTotal = (data, field) => {
      if (!data.length) return 0;
      const weeklyValues = groupByWeek(data, field);
      const total = Object.values(weeklyValues).reduce((sum, val) => sum + val, 0);
      return total;
    };

    const calculateServiceLevel = (data) => {
      if (!data.length) return 0;
      const weeklyData = groupByWeek(data, 'end_inventory');
      const stockoutWeeks = Object.values(weeklyData).filter(val => val <= 0).length;
      const serviceLevel = ((Object.keys(weeklyData).length - stockoutWeeks) / Object.keys(weeklyData).length) * 100;
      return serviceLevel;
    };

    // Return the metrics object
    const metrics = {
      "Total Outbound": {
        "Historical": Math.round(calculateTotal(historicalData, 'outbound_quantity')),
        "Forecasted": Math.round(calculateTotal(forecastedData, 'outbound_quantity'))
      },
      "Total Inbound": {
        "Historical": Math.round(calculateTotal(historicalData, 'inbound_quantity')),
        "Forecasted": Math.round(calculateTotal(forecastedData, 'inbound_quantity'))
      },
      "Average Inventory": {
        "Historical": Math.round(calculateAverage(historicalData, 'end_inventory')),
        "Forecasted": Math.round(calculateAverage(forecastedData, 'end_inventory'))
      },
      "Average Weeks of Inventory": {
        "Historical": Math.round(calculateAverage(historicalData, 'weeks_of_inventory') * 10) / 10,
        "Forecasted": Math.round(calculateAverage(forecastedData, 'weeks_of_inventory') * 10) / 10
      },
      "Service Level": {
        "Historical": Math.round(calculateServiceLevel(historicalData)),
        "Forecasted": Math.round(calculateServiceLevel(forecastedData))
      },
      "Average Orders": {
        "Historical": Math.round(calculateAverage(historicalData, 'orders_placed')),
        "Forecasted": Math.round(calculateAverage(forecastedData, 'orders_placed'))
      }
    };

    return metrics;
  };

  const renderEchelonMetrics = (metrics) => {
    if (!metrics) return null;

    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {selectedEchelonEntity === 'All' ? 'Overall Metrics' : `${selectedEchelonEntity} Metrics`}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              <TableCell align="right">Historical</TableCell>
              <TableCell align="right">Forecasted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(metrics).map(([metric, values]) => (
              <TableRow key={metric} hover>
                <TableCell 
                  sx={{ 
                    fontWeight: 'medium',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {metric}
                </TableCell>
                <TableCell align="right">
                  {values.Historical.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {values.Forecasted.toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const renderDetailedDataTable = (data) => {
    if (!data) return null;

    const { weeks, metrics } = data;
    
    return (
      <Paper elevation={3} sx={{ mt: 2, overflow: 'auto', maxHeight: '400px' }}>
        <Table size="small" stickyHeader padding="none">
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 1, pr: 1 }}>Metric</TableCell>
              {weeks.map(week => (
                <TableCell 
                  key={week} 
                  align="right"
                  sx={{ 
                    minWidth: '70px',
                    whiteSpace: 'pre-line', // Allow line breaks for year
                    pl: 1,
                    pr: 1
                  }}
                >
                  {formatWeekLabel(week)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(metrics).map(([metric, values]) => (
              <TableRow key={metric} hover>
                <TableCell 
                  sx={{ 
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'background.paper',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    pl: 1,
                    pr: 1,
                    fontSize: '0.875rem'
                  }}
                >
                  {metric.split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                  }
                </TableCell>
                {weeks.map(week => (
                  <TableCell 
                    key={week} 
                    align="right"
                    sx={{ 
                      pl: 1, 
                      pr: 1,
                      fontSize: '0.875rem'
                    }}
                  >
                    {typeof values[week] === 'number' 
                      ? values[week].toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        }) 
                      : '0'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const renderCombinedChart = (data) => {
    if (!data?.length) return null;

    return (
      <Box sx={{ height: 400, mb: 3 }}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <CustomXAxis dataKey="master_week" />
            <CustomYAxis yAxisId="left" />
            <CustomYAxis 
              yAxisId="right" 
              orientation="right"
              domain={[0, 25]}
            />
            <Tooltip
              labelFormatter={(_, payload) => {
                if (!payload || !payload[0]) return '';
                const dataPoint = payload[0].payload;
                return formatAxisDate(dataPoint.master_week);
              }}
              formatter={(value, name) => [parseFloat(value).toLocaleString(), name]}
            />
            <Legend />
            
            <Bar
              dataKey="inbound_quantity"
              fill={theme.palette.primary.light}
              name="Inbound"
              opacity={0.5}
            />
            <Bar
              dataKey="outbound_quantity"
              fill={theme.palette.secondary.light}
              name="Outbound"
              opacity={0.5}
            />
            <Line
              type="monotone"
              dataKey="end_inventory"
              stroke={theme.palette.primary.main}
              name="Inventory"
              yAxisId="left"
              strokeDasharray={d => d.data_type === 'Forecasted' ? "5 5" : "0"}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="weeks_of_inventory"
              stroke={theme.palette.error.main}
              name="Weeks of Inventory"
              yAxisId="right"
              strokeDasharray={d => d.data_type === 'Forecasted' ? "5 5" : "0"}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const determineDataType = (date) => {
    if (!date) return 'Historical';
    
    try {
      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) return 'Historical';
      
      const today = new Date();
      const lastMonday = new Date(today);
      lastMonday.setDate(today.getDate() - today.getDay() + 1);
      lastMonday.setHours(0, 0, 0, 0);
      
      return dateObj <= lastMonday ? 'Historical' : 'Forecasted';
    } catch (e) {
      return 'Historical';
    }
  };

  // Update the API URL configuration to handle Azure deployment
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/api/simulation-results`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const processedData = data.map(row => {
          const parsedDate = parseDate(row.master_week);
          return {
            ...row,
            master_week: parsedDate || new Date(),
            data_type: row.data_type || determineDataType(parsedDate)
          };
        });
        
        setResults(processedData);
        
        const items = getUniqueValues(processedData, 'item');
        setSelectedItems(items);
        setSelectedImporters(getUniqueValues(processedData, 'importer'));
        setSelectedDistributors(getUniqueValues(processedData, 'distributor'));
        setSelectedRetailers(getUniqueValues(processedData, 'retailer'));
        
        if (items.length > 0) {
          setSelectedDetailItem(items[0]);
        }
      })
      .catch(error => {
        setError(`Failed to fetch data: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!results) return;

    const filtered = results.filter(row => 
      selectedItems.includes(row.item) &&
      (selectedImporters.includes(row.importer) || !row.importer) &&
      (selectedDistributors.includes(row.distributor) || !row.distributor) &&
      (selectedRetailers.includes(row.retailer) || !row.retailer)
    );

    const processedData = filtered.map(row => ({
      ...row,
      end_inventory: parseFloat(row.end_inventory) || 0,
      outbound_quantity: parseFloat(row.outbound_quantity) || 0,
      inbound_quantity: parseFloat(row.inbound_quantity) || 0
    }));

    setFilteredResults(processedData);
  }, [results, selectedItems, selectedImporters, selectedDistributors, selectedRetailers]);

  useEffect(() => {
    if (results && selectedDetailItem) {
      const newRetailerData = results.filter(row => 
        row.echelon === 'Retailer' && 
        row.item === selectedDetailItem
      );
      setRetailerData(newRetailerData);

      const retailerDemandData = processRetailerDemand(
        results.filter(row => row.item === selectedDetailItem)
      );
      setRetailerDemand(retailerDemandData);
    }
  }, [results, selectedDetailItem]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: 'background.default', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom>
        Multi-Echelon Supply Chain Simulation
      </Typography>

      {/* Main Content Area */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Item-Level Analysis */}
          <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Item-Level Analysis
            </Typography>
            
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Select an Item for Detailed Analysis</InputLabel>
              <Select
                value={selectedDetailItem}
                onChange={(e) => setSelectedDetailItem(e.target.value)}
              >
                {getUniqueValues(results || [], 'item').map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Retailer Demand Visualization */}
            {selectedDetailItem && (
              <>
                <Typography variant="h6" gutterBottom>
                  Retailer Demand Over Time for Item: {selectedDetailItem}
                </Typography>
                
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Select Retailer</InputLabel>
                  <Select
                    value={selectedRetailer}
                    onChange={(e) => setSelectedRetailer(e.target.value)}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {retailerData && getUniqueValues(retailerData, 'company').map(retailer => (
                      <MenuItem key={retailer} value={retailer}>{retailer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    {renderRetailerDemandChart(retailerDemand?.data, retailerDemand?.retailers)}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {renderDemandStatistics(calculateDemandStatistics(retailerDemand?.data, selectedRetailer))}
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>

          {/* Echelon Details */}
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Echelon Details
            </Typography>
            
            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
              <Tab label="Importer" />
              <Tab label="Distributor" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
              {selectedTab === 0 && renderEchelonDetails('Importer')}
              {selectedTab === 1 && renderEchelonDetails('Distributor')}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

// PropTypes for custom axis components
CustomXAxis.propTypes = {
  dataKey: PropTypes.string,
  type: PropTypes.string,
  tickFormatter: PropTypes.func
};

CustomYAxis.propTypes = {
  tickFormatter: PropTypes.func,
  orientation: PropTypes.string
};

export default MultiEchelonSimulation;  