import React, { useState } from 'react';
import { uploadApi } from '../../services/api';

const UploadForm = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type !== 'text/csv') {
            setError('Please select a CSV file');
            return;
        }
        setFile(selectedFile);
        setError(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setError('Please select a file');
            return;
        }

        setLoading(true);
        setError(null);
        setSuccess(false);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await uploadApi.uploadFile(formData);

            console.log('Upload response:', response.data);
            
            if (response.data.message) {
                setSuccess(true);
                setFile(null);
                // Reset file input
                event.target.reset();
            } else {
                throw new Error('Invalid server response');
            }
        } catch (error) {
            console.error('Upload error:', error);
            setError(
                error.response?.data?.details || 
                error.response?.data?.error || 
                error.message || 
                'Upload failed'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Upload Data File</h2>
            
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Select CSV File
                    </label>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        className="mt-1 block w-full text-sm text-gray-500
                                 file:mr-4 file:py-2 file:px-4
                                 file:rounded-md file:border-0
                                 file:text-sm file:font-semibold
                                 file:bg-blue-50 file:text-blue-700
                                 hover:file:bg-blue-100"
                    />
                </div>

                {error && (
                    <div className="text-sm text-red-600 bg-red-50 p-3 rounded">
                        {error}
                    </div>
                )}

                {success && (
                    <div className="text-sm text-green-600 bg-green-50 p-3 rounded">
                        File uploaded successfully!
                    </div>
                )}

                <button
                    type="submit"
                    disabled={!file || loading}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                        ${!file || loading 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-600 hover:bg-blue-700'}`}
                >
                    {loading ? 'Uploading...' : 'Upload'}
                </button>
            </form>
        </div>
    );
};

export default UploadForm; 