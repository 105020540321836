import React, { useState, useEffect } from 'react';
import { supplyChainApi } from '../../services/api';

const JointReplenishment = () => {
  const [selectedWeek, setSelectedWeek] = useState('');
  const [selectedFromCompany, setSelectedFromCompany] = useState('');
  const [selectedToCompany, setSelectedToCompany] = useState('');
  const [companies, setCompanies] = useState({ retailers: [], distributors: [], importers: [] });
  const [orderRecommendation, setOrderRecommendation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [parameters, setParameters] = useState({
    containerCapacity: 40,
    safetyStockMultiplier: 1.5,
    maxCoveragePeriod: 8,
    reviewPeriod: 1,
  });

  // Fetch companies on component mount
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await supplyChainApi.getCompanies();
        setCompanies(response.data);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Failed to load companies');
      }
    };
    fetchCompanies();
  }, []);

  const handleParameterChange = (param, value) => {
    console.log('Parameter changed:', param, value);
    setParameters(prev => ({
      ...prev,
      [param]: Number(value)
    }));
  };

  const fetchJointOrder = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const requestParams = {
        week: selectedWeek,
        fromCompany: selectedFromCompany,
        toCompany: selectedToCompany,
        containerCapacity: parameters.containerCapacity,
        safetyStockMultiplier: parameters.safetyStockMultiplier,
        maxCoveragePeriod: parameters.maxCoveragePeriod,
        reviewPeriod: parameters.reviewPeriod
      };
      
      console.log('Making request with params:', requestParams);

      const response = await supplyChainApi.getJointReplenishment(requestParams);

      console.log('Response received:', response.data);
      setOrderRecommendation(response.data);
    } catch (err) {
      console.error('Error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status
      });
      setError(err.response?.data?.error || 'Failed to calculate joint replenishment order');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submit clicked');
    if (selectedWeek && selectedFromCompany && selectedToCompany) {
      console.log('Fetching joint order...');
      fetchJointOrder();
    } else {
      console.log('Missing required fields:', {
        week: selectedWeek,
        fromCompany: selectedFromCompany,
        toCompany: selectedToCompany
      });
    }
  };

  // Helper function to get available "to" companies based on selected "from" company
  const getAvailableToCompanies = () => {
    if (!selectedFromCompany) return [];
    
    // If selected from company is a retailer, they can order from distributors
    if (companies.retailers.find(c => c.id === selectedFromCompany)) {
      return companies.distributors;
    }
    // If selected from company is a distributor, they can order from importers
    if (companies.distributors.find(c => c.id === selectedFromCompany)) {
      return companies.importers;
    }
    return [];
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-bold mb-4">Joint Replenishment Order</h2>
        
        <form onSubmit={handleSubmit}>
          {/* Parameters Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Replenishment Parameters</h3>
            <div className="grid grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Container Capacity (pallets)
                </label>
                <input
                  type="number"
                  value={parameters.containerCapacity}
                  onChange={(e) => handleParameterChange('containerCapacity', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Safety Stock Multiplier
                </label>
                <input
                  type="number"
                  value={parameters.safetyStockMultiplier}
                  onChange={(e) => handleParameterChange('safetyStockMultiplier', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="0"
                  step="0.1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Max Coverage Period (weeks)
                </label>
                <input
                  type="number"
                  value={parameters.maxCoveragePeriod}
                  onChange={(e) => handleParameterChange('maxCoveragePeriod', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Review Period (weeks)
                </label>
                <input
                  type="number"
                  value={parameters.reviewPeriod}
                  onChange={(e) => handleParameterChange('reviewPeriod', e.target.value)}
                  className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
            </div>
          </div>

          {/* Selection Controls */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Week
              </label>
              <input
                type="date"
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(e.target.value)}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                From Company
              </label>
              <select
                value={selectedFromCompany}
                onChange={(e) => {
                  setSelectedFromCompany(e.target.value);
                  setSelectedToCompany(''); // Reset to company when from company changes
                }}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option value="">Select From Company</option>
                {companies.retailers.map(company => (
                  <option key={company.id} value={company.id}>{company.name} (Retailer)</option>
                ))}
                {companies.distributors.map(company => (
                  <option key={company.id} value={company.id}>{company.name} (Distributor)</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                To Company
              </label>
              <select
                value={selectedToCompany}
                onChange={(e) => setSelectedToCompany(e.target.value)}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                disabled={!selectedFromCompany}
              >
                <option value="">Select To Company</option>
                {getAvailableToCompanies().map(company => (
                  <option key={company.id} value={company.id}>{company.name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Add Submit Button */}
          <div className="flex justify-end mb-6">
            <button
              type="submit"
              disabled={!selectedWeek || !selectedFromCompany || !selectedToCompany || loading}
              className={`px-4 py-2 rounded-md text-white font-medium
                ${(!selectedWeek || !selectedFromCompany || !selectedToCompany || loading)
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
                }`}
            >
              {loading ? 'Calculating...' : 'Calculate Order'}
            </button>
          </div>
        </form>

        {/* Results Display */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <p>Calculating order recommendation...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        ) : orderRecommendation && orderRecommendation.containers?.length > 0 ? (
          <div className="space-y-6">
            {/* Summary Cards */}
            <div className="grid grid-cols-4 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Total Containers</h3>
                <p className="text-2xl font-bold">{orderRecommendation.totalContainers || 0}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Avg Container Utilization</h3>
                <p className="text-2xl font-bold">
                  {(orderRecommendation.avgContainerUtilization || 0).toFixed(1)}%
                </p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Total Items</h3>
                <p className="text-2xl font-bold">{orderRecommendation.totalItems || 0}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-sm font-semibold text-gray-600">Total Pallets</h3>
                <p className="text-2xl font-bold">{orderRecommendation.totalPallets || 0}</p>
              </div>
            </div>

            {/* Container Breakdown */}
            {orderRecommendation.containers.map((container, idx) => (
              <div key={idx} className="border rounded-lg overflow-hidden">
                <div className="bg-gray-50 px-4 py-2 border-b">
                  <h3 className="font-semibold">Container {idx + 1}</h3>
                  <p className="text-sm text-gray-600">
                    Utilization: {(container.utilization || 0).toFixed(1)}%
                  </p>
                </div>
                
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Order Quantity (Pallets)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Current IP
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        ROP
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Weeks Coverage
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Order Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {container.items.map((item, itemIdx) => (
                      <tr key={itemIdx}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.orderQuantity || 0}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.currentIP || 0}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.rop || 0}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {(item.weeksCoverage || 0).toFixed(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            item.orderType === 'Required' ? 'bg-red-100 text-red-800' :
                            item.orderType === 'Opportunistic' ? 'bg-green-100 text-green-800' :
                            'bg-blue-100 text-blue-800'
                          }`}>
                            {item.orderType || 'Container Fill'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12 text-gray-500">
            Select a week and companies to calculate joint replenishment orders
          </div>
        )}
      </div>
    </div>
  );
};

export default JointReplenishment; 