import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const forecastApi = {
  getForecast: (data) => api.post('/forecast', data),
  getOptions: () => api.get('/forecast/options')
};

export const supplyChainApi = {
  getOverview: (data) => api.post('/supply-chain/overview', data),
  getJointReplenishment: (params) => api.get('/supply-chain/joint-replenishment', { params }),
  getProducts: () => api.get('/supply-chain/products'),
  getCompanies: () => api.get('/supply-chain/companies')
};

export const ordersApi = {
  getDistributors: () => api.get('/orders/distributors'),
  getRecommendations: (data) => api.post('/orders/recommendations', data)
};

export const uploadApi = {
  uploadFile: (formData) => api.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
};

export default api; 